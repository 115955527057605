import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // redirect: '/activity/shopcouponevent',
    // children: [
    //   {
    //     path: 'activity/shopcouponevent',
    //     name: 'shopcouponevent',
    //     meta: {
    //       title: '好利来城市限定优惠券'
    //     },
    //     component: () => import('../views/activity/shopcouponevent/index.vue')
    //   }
    // ]
  },
  {
    path: '/guangye',
    name: 'guangye',
    meta: {
      title: '光启市心动甜品店'
    },
    component: () => import('../views/guangye/index.vue')
  },
  {
    path: '/activity/shopcouponevent',
    name: 'shopcouponevent',
    meta: {
      title: '好利来城市限定优惠券'
    },
    component: () => import('../views/activity/shopcouponevent/index.vue')
  },
  {
    path: '/ad',
    name: 'ad',
    meta: {
      title: '详情'
    },
    component: () => import('../views/ad/index.vue')
  },
  {
    path: '/wechat_login',
    name: 'wechat_login',
    meta: {
      title: '微信授权'
    },
    component: () => import('../views/wechat_login/index.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
